<template>
  <div class="index_boxs">
    <header class="head_main">
      <swiper
        class="swiper-container"
        :autoplay="{ autoplay: true }"
        loop
        :pagination="{
          clickable: true,
        }"
      >
        <swiper-slide
          class="swiper-slide"
          v-for="(item, i) in bannerData"
          :key="i"
          :virtualIndex="i"
          @click="jumpChange(item)"
        >
          <img :src="imgUrl+item.pic" />
        </swiper-slide>
      </swiper>
    </header>
    <main class="index_mian">
      <div class="item_mian">
        <div class="introduce_box">
          <el-row :gutter="24">
            <el-col :span="13"><div class="grid-content ep-bg-purple"/>
              <img
                  style="width: 100%;padding-bottom: 84px;"
                  :src="imgUrl+firstPage"
                  class="ntroduce_img"
              />
            </el-col>
            <el-col :span="11"><div class="grid-content ep-bg-purple" />
              <div class="text_introduce">
                <img
                    style="width: 100%;"
                    src="@/assets/index/left_tail.png"
                    class="ntroduce_img_tail"
                />
                <div class="text_conent project_introduction" v-html="introduction">
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="10"><div class="grid-content "/>
              <div class="left_bottom">
                 <div style="width: 90%">
                   <img
                       style="width: 100%;padding-right: 50px"
                       :src="imgUrl+firstPage1"
                       class="ntroduce_img boder_head bottom_ntroduce_img"
                   />
                 </div>
                <div class="text_title" v-html="introduction1"></div>
              </div>
            </el-col>
            <el-col :span="14"><div class="grid-content " />
              <img
                  style="width: 100%"
                  :src="imgUrl+firstPage2"
                  class="bottom_right_img"
              />
            </el-col>
          </el-row>
<!--          <div class="item_introduce">-->
<!--              <img-->
<!--                  :src="imgUrl+firstPageData[0].pics"-->
<!--                  class="ntroduce_img"-->
<!--              />-->
<!--            <div class="text_introduce">-->
<!--              <img-->
<!--                src="@/assets/index/left_tail.png"-->
<!--                class="ntroduce_img_tail"-->
<!--              />-->
<!--              <div class="text_conent project_introduction" v-html="firstPageData[3].introduction">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="item_introduce">-->
<!--            <div class="left_bottom">-->
<!--              <img-->
<!--                :src="imgUrl+firstPageData[1].pics"-->
<!--                class="ntroduce_img boder_head bottom_ntroduce_img"-->
<!--              />-->
<!--              <div class="text_title" v-html="firstPageData[1].introduction"></div>-->
<!--            </div>-->
<!--            <img-->
<!--              :src="imgUrl+firstPageData[2].pics"-->
<!--              class="bottom_right_img"-->
<!--            />-->
<!--            &lt;!&ndash; <div class="text_introduce padding97">-->
<!--                <div class="text_describe">传统文化与现代文明交相辉映、-->
<!--                    <br/>中华文化与世界文明协调共融的-->
<!--                    <br/>世界级文化艺术消费目的地-->
<!--                </div>-->
<!--            </div> &ndash;&gt;-->
<!--          </div>-->
        </div>
      </div>
      <div class="item_mian">
        <!-- <img src="@/assets/select_idx.png" class="item_icon" />
        <div class="item_title">隆福概览</div>
        <div class="item_title1">ABOUT LONGFU</div> -->
        <div class="item_video">
          <video
              ref="myVideo"
              src="@/assets/index/video.mp4"
              controls
              width="100%"
              height="100%"
              poster="@/assets/index/video.png"
              type="video/mp4"
              class="video-box"
          ></video>
          <!-- <img src="@/assets/index/video.png" class="video_icon" /> -->
          <img src="@/assets/index/play.png" class="play_icon" v-show="!videoState" @click="operateVideo"/>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance} from "vue";
import router from "@/router/index";
import {getFirstpageManage} from "../../api";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import {getCarousel,imgUrl} from '@/api/index';

const {proxy} = getCurrentInstance()

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
const bannerData = ref([]);
const videoState = ref(false) // 视频播放状态
const firstPage=ref('')
const introduction=ref('')
const firstPage1=ref('')
const firstPage2=ref('')
const introduction1=ref('')
function operateVideo() {
  // proxy.$refs.myVideo.play()
  if (proxy.$refs.myVideo.paused) {
      proxy.$refs.myVideo.play();
      videoState.value = true;
    } else {
      proxy.$refs.myVideo.pause();
      videoState.value = false;
    }
}

const getCarouselList = () => {
  getCarousel({name:'banner'}).then(res=>{
    if(res.code==1){
      bannerData.value=res.data;
    }
  })
}
getCarouselList()

function jumpChange(item) {
  router.push({
    path: "/Activity/detail",
    query:{
      id:item.link
    }
  })
}

const firstPageData = ref([]);
const firstPageManage = () => {
  getFirstpageManage().then(res=>{
    if(res.code==1){
      firstPageData.value = res.data;
      firstPage.value=res.data[0].pics;
      firstPage1.value=res.data[1].pics;
      firstPage2.value=res.data[2].pics;
      introduction.value=res.data[3].introduction;
      introduction1.value=res.data[1].introduction;
    }
  })
}
firstPageManage()

// const windowWidth = ref(window.innerWidth);
//
// function handleResize() {
//   windowWidth.value = window.innerWidth;
//   console.log(windowWidth.value,windowWidth);
// }
//
// onMounted(() => {
//   window.addEventListener('resize', handleResize);
// });
//
// onUnmounted(() => {
//   window.removeEventListener('resize', handleResize);
// })
</script>
<style lang="scss">
  .left_bottom {
    text-align: left;
    padding-top: 30px;
    ::-webkit-scrollbar {display:none}
    .text_title p{
      font-size: 29px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .project_introduction {
    p strong {
      margin-bottom: 28px;
    }
  }
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 1536px) {
  .index_mian{
    width: 1250px !important;
  }
  .swiper-slide img {
    //height: 700px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .index_mian{
    width: 1100px !important;
  }
  .swiper-slide img {
    //height: 550px !important;
  }
}
@media only screen and (max-width: 1097.14px) {
  .index_mian{
    width:880px !important;
  }
  .swiper-slide img {
    //height: 500px !important;
  }
  .text_introduce{
    margin-left: 60px !important;
  }
  .text_title{
    height: 100px !important;
  }
}
@media only screen and (max-width: 768px) {
  .index_mian{
    width:680px !important;
  }
  .swiper-slide img {
    //height: 350px !important;
  }
  .text_introduce{
    margin-left: 30px !important;
  }
  .text_title{
    height: 50px !important;
  }
}
@media only screen and (max-width: 640px) {
  .index_mian{
    width:580px !important;
  }
  .swiper-slide img {
    //height: 280px !important;
  }
}

//@media only screen and (min-width: 2100px) {
//  .index_mian{
//    width: 1600px !important;
//  }
//}
//@media only screen and (min-width: 2200px) {
//  .swiper-slide img {
//    width: 100% !important;
//    max-height: 1000px !important;
//  }
//}
//@media only screen and (min-width: 2400px) {
//  .swiper-slide img {
//    width: 100% !important;
//    max-height: 1000px !important;
//  }
//  .index_mian{
//    width: 1800px !important;
//  }
//}@media only screen and (min-width: 2560px) {
//  .index_mian{
//    width: 1900px !important;
//  }
//  .project_introduction {
//    ::v-deep p {
//       //color: red !important;
//       font-size: 22px !important;
//    }
//    ::v-deep p strong {
//      margin-top: 20px;
//      font-size: 24px !important;
//    }
//  }
//  .text_conent{
//    height: 400px !important;
//  }
//}
//@media only screen and (min-width: 2880px) {
//  .swiper-slide img {
//    width: 100% !important;
//    max-height: 1200px !important
//  }
//  .index_mian{
//    width: 2150px !important;
//  }
//  .text_conent{
//    height: 500px !important;
//  }
//}
//@media only screen and (min-width: 3840px) {
//  .swiper-slide img {
//    width: 100% !important;
//    max-height: 1500px !important;
//  }
//  .index_mian{
//    width: 2800px !important;
//  }
//  .project_introduction {
//    ::v-deep p {
//      //color: red !important;
//      font-size: 28px !important;
//    }
//    ::v-deep p strong {
//      margin-top: 20px;
//      font-size: 32px !important;
//    }
//  }
//  .text_conent{
//    height: 600px !important;
//  }
//  .text_title{
//    ::v-deep p{
//      font-size: 38px;
//    }
//  }
//}
//@media only screen and (min-width: 5760px) {
//  .swiper-slide img {
//    width: 100% !important;
//    max-height: 2250px !important;
//  }
//  .index_mian{
//    width: 4100px !important;
//  }
//  .project_introduction {
//    ::v-deep p {
//      //color: red !important;
//      font-size:48px !important;
//      margin-top: 20px;
//
//    }
//    ::v-deep p strong {
//      font-size: 52px !important;
//    }
//  }
//  .text_conent{
//    margin-top: 50px !important;
//    height: 800px !important;
//  }
//  .text_title{
//    ::v-deep p{
//      font-size: 54px;
//    }
//  }
//}
//@media only screen and (min-width: 7680px) {
//  .index_mian{
//    width: 5500px !important;
//  }
//  .project_introduction {
//    ::v-deep p {
//      //color: red !important;
//      font-size:56px !important;
//      margin-top: 20px;
//
//    }
//    ::v-deep p strong {
//      font-size: 58px !important;
//    }
//  }
//  .text_title{
//    ::v-deep p{
//      font-size: 66px;
//    }
//  }
//}
.index_boxs {
  font-family: PingFang SC;
  z-index: 1;
  max-width: 1920px;
  margin: 0 auto;
  .head_main {
    // border: 30px solid #ffda00;
  }

  .swiper-container {
    --swiper-pagination-color: #00ff33; /* 两种都可以 */
  }
  .swiper-slide img {
    width: 100%;
    //max-height: 877px;
    display: flex;
  }

  .index_mian {
    // padding: 0 260px 81px 260px;
    width: 1400px;
    margin: 0 auto;
    .item_mian {
      margin-top: 79px;
      //text-align: center;

      .item_icon {
        width: 41px;
        height: 28px;
      }

      .item_title {
        margin-top: 10px;
        font-size: 40px;
        font-weight: 400;
        color: #8c857b;
      }

      .item_title1 {
        font-size: 40px;
        font-family: Gotham;
        font-weight: 400;
        color: #eeeeee;
        margin-bottom: 30px;
      }
    }
  }
}
.introduce_box {
  .item_introduce {
    width: 1400px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid white;
    padding-bottom: 84px;
    //flex-flow:row wrap;
    &:last-child {
      padding-bottom: 0;
    }
    &:last-child {
      border: none;
    }
    .ntroduce_img {
      // width: 50%;
      //width: 680px;
      width: 100%;
      height: 692px;
      // &:last-child {
      //   width: calc(50% - 4px);
      // }
    }

    .bottom_ntroduce_img {
      width: 420px;
      height: 308px;
    }

    .bottom_right_img {
      width: 680px;
      height: 550px;
      padding-left: 100px;
    }

    .ntroduce_img_tail {
      width: 420px;
      height: 227px;
    }




    // .boder_mian {
    //   border-right: 4px solid white;
    // }
  }
}
.text_title {
  width: 420px;
  height: 210px;
  overflow-y: scroll;
  font-size: 33px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  padding: 0 0 28px;
  margin-top: 38px;
}
.boder_head {
  border-left: 4px solid white;
}
.text_introduce {
  //width: 420px;
  // height: 698px;
  // flex: 1;
  // height: 710px;
  background: #FFFFFF;
  margin-left: 100px;
  box-sizing: border-box;
  color: #FFFFFF;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.padding97 {
    padding: 0 97px;
  }

  .text_describe {
    font-size: 36px;
    font-weight: 100;
    color: #FFFFFF;
  }

  .text_conent {
    height: 330px;
    margin-top: 38px;
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 9; /*这里设置几行*/
    overflow-y: scroll;
    line-height: 32px;
    font-family: PingFang SC;
    color: #333333;
  }

  .text_btn {
    width: 78px;
    height: 32px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 100;
    color: #333333;
    padding-top: 33px;
    border-bottom: 4px solid #FFDA00;
  }
}
.item_video {
  width: 100%;
  position: relative;
  border-top: 34px solid #000000;
  border-bottom: 34px solid #000000;
  background: #000000;
  margin: 0 auto;
  margin-bottom: 50px;

  .video-box {
    box-sizing: border-box;
    border: 0;
    display: block;
    width: 100%;
    height: 100%;
    outline: none !important;
  }

  .video_icon {
    width: 100%;
    display: flex;
  }

  .play_icon {
    width: 80px;
    height: 81px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>
